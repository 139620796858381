import "./App.css";
import globalContext from "./Contexts/GlobalContext";
import { useEffect, useState } from "react";
import routes from "./routes";
import { useRoutes } from "react-router-dom";

function App() {
  const router = useRoutes(routes);

  const [status, setStatus] = useState(() => {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  });
  useEffect(() => {
    window.ononline = (e) => {
      setStatus(true);
    };
    window.onoffline = (e) => {
      setStatus(false);
    };
  }, [status]);
  return (
    <>
      <globalContext.Provider>
        {!status ? (
          <>{/* <Offline setStatus={setStatus} /> */}</>
        ) : (
          <>{router}</>
        )}
      </globalContext.Provider>
    </>
  );
}

export default App;
