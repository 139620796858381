import { Navigate } from "react-router-dom";
import ComingSoon from "./pages/ComingSoon";

let routes = [
  {
    path: "/",
    element: <ComingSoon />,
  },

  // ------------------- 404 Code ------------------- //
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export default routes;
