import React from "react";

export default function ComingSoon() {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center  bg-[#f5f5f5]">
      <div className="w-full  flex items-center justify-center  ">
        <img
          src="../assets/images/shen.png"
          className="w-[250px] h-[250px] object-contain mix-blend-multiply"
        />
      </div>

      <div className="w-full flex flex-col items-center justify-center mt-[16px]">
        <span className="w-full flex items-center justify-center text-[28px] font-bold text-[#474747]">
          Updating
        </span>
        <p className=" w-full text-center">
          You can contact us by sending an email to&nbsp;
          <span className="text-[#03678A] font-bold">
            info@shenzheneastward.com
          </span>
        </p>

        <button
          className="w-[250px] h-[40px] flex items-center justify-center mt-[32px] rounded-[5px] bg-[#03678A] text-[#fff] hover:bg-[#2e2e2e]"
          onClick={() => {
            window.open("mailto:info@shenzheneastward.com");
          }}
        >
          Send Email
        </button>
      </div>
    </div>
  );
}
